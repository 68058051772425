import BreadCrumb from "components/Common/BreadCrumb";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Stepper } from "react-form-stepper";
import { Card, CardBody, Container } from "reactstrap";
//
import ChooseObject from "./ChooseObject";
import Mapping from "./Mapping";
import ImportData from "./ImportData";
import { StepDTO } from "react-form-stepper/dist/components/Step/StepTypes";
import Prepare from "./Prepare";

const Import = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [partner, setPartner] = useState<any>();
  const [company, setCompany] = useState<any>();
  const [nextPrepare, setNextPrepare] = useState<any>();
  const [nextImport, setNextImport] = useState<any>();
  const [template, setTemplate] = useState<any>();

  const steps = [
    { label: t("cms_media.request_object") },
    { label: t("cms_media.mapping") },
    { label: t("cms_media.preview") },
    { label: t("cms_media.import") },
  ] as StepDTO[];

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNextPrepare = (value:any) => {
    setNextPrepare(value)
    nextStep()
  }

  const handleNextImport = (value:any) => {
    setNextImport(value)
    nextStep()
  }

  return (
    <React.Fragment>
     <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("cms_media.import")} pageTitle={'Presscon Ai'} />
          <Card>
            <CardBody>
              <div className="d-flex align-items-center justify-content-center w-100">
                <Stepper
                  steps={steps}
                  activeStep={activeStep}
                  style={{ width: "100%" }}
                  stepClassName="custom-step"
                />
              </div>
              <div>
                {activeStep === 0 && (
                  <ChooseObject
                    activeStep={activeStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    partner={partner}
                    company={company}
                    template={template}
                    setCompany={setCompany}
                    setPartner={setPartner}
                    setTemplate={setTemplate}
                  />
                )}
                {activeStep === 1 && (
                  <Mapping
                    partner={partner}
                    company={company}
                    template={template}
                    nextStep={handleNextPrepare}
                    prevStep={prevStep}
                    activeStep={activeStep}
                  />
                )}
                {activeStep === 2 && <Prepare company_id={company?.value} partner_name={nextPrepare?.partner_name} job_id={nextPrepare?.job_id} nextStep={handleNextImport} prevStep={prevStep}/>}
                {activeStep === 3 && <ImportData nextImport={nextImport} nextStep={()=>{
                  setActiveStep(0)
                }} />}
              </div>
            </CardBody>
          </Card>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default Import;
