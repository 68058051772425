import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "components/Hooks/UserHooks";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes/allRoutes";
import { isSuperAdmin } from "utils";
import pressconLogo from "../assets/images/presscon/logo.png";

const Navdata = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const { userPermissions } = useRole();

  const [menuActive, setMenuActive] = useState<string>("DASHBOARD");

  // Multi Level
  const [isCurrentState, setIsCurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
  }, [history]);

  const menuItems: any = [
    {
      id: "dashboard",
      label: t("Dashboards"),
      icon: <FeatherIcon icon="home" className="icon-dual" />,
      link: ROUTES.DASHBOARD,
      click: function (e: any) {
        setMenuActive((prev) => "DASHBOARD");
        setIsCurrentState((prev) => "Dashboard");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "DASHBOARD",
      isShow: isHavePermissionRole(
        ROLES_FOR_APP.DASHBOARD_VIEW,
        userPermissions
      ),
      // subItems: []
    },
    {
      id: "multilevel4",
      label: t("media.Management"),
      icon: <FeatherIcon icon="command" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "MEDIA");
        setIsCurrentState((prev) => "Media");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "MEDIA",
      isShow: isHavePermissionRole(
        ROLES_FOR_APP.MEDIA_MANAGEMENT,
        userPermissions
      ),
      subItems: [
        {
          id: "media",
          label: t("media.List"),
          link: ROUTES.MEDIA,
          isShow: true,
          stateVariables: true,
        },
        {
          id: "revenue",
          label: t("media.Revenue"),
          link: ROUTES.MEDIA_REVENUE,
          isShow: true,
        },
      ],
    },
    {
      id: "multilevel5",
      label: t("Statistics"),
      icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "STATISTICS");
        setIsCurrentState((prev) => "Statistics");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "STATISTICS",
      isShow: isHavePermissionRole(
        [
          ROLES_FOR_APP.MEDIA_LOG_LIST,
          ROLES_FOR_APP.VISITOR_STATISTICS_LIST,
          ROLES_FOR_APP.MEDIA_STATISTICS_LIST,
          ROLES_FOR_APP.CATEGORY_STATISTICS_LIST,
          ROLES_FOR_APP.CAMPAIGN_STATISTICS_LIST,
          ROLES_FOR_APP.NEWS_STATISTICS_LIST,
          ROLES_FOR_APP.KEYWORD_INFLOW_LIST,
          ROLES_FOR_APP.KEYWORD_STATISTICS_LIST,
          ROLES_FOR_APP.KEYWORD_ANALYSIS_LIST,
        ],
        userPermissions
      ),
      subItems: [],
    },
    {
      id: "multilevel13",
      label: t("articles_management.articles_management"),
      icon: <FeatherIcon icon="file-text" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "ARTICLES_MANAGEMENT");
        setIsCurrentState((prev) => "Articles Management");
        updateIconSidebar(e);
      },
      stateVariables:
        menuActive === "ARTICLES_MANAGEMENT" ||
        menuActive === "ARTICLES_MANAGEMENT",
      isShow: isHavePermissionRole(
        ROLES_FOR_APP.ARTICLE_MANAGEMENT,
        userPermissions
      ),
      subItems: [
        {
          id: "article_statistics",
          label: t("articles_management.articles_statistics"),
          link: ROUTES.ARTICLES_MANAGEMENT.ARTICLE_STATISTICS,
          isShow: true,
          stateVariables: true,
        },
        {
          id: "revenue",
          label: t("articles_management.articles_list"),
          link: ROUTES.ARTICLES_MANAGEMENT.ARTICLE_LIST,
          isShow: true,
        },
      ],
    },
    {
      id: "inquiry-management",
      label: t("inquiry.menu_title"),
      click: function (e: any) {
        setMenuActive((prev) => "INQUIRY");
        setIsCurrentState((prev) => "inquiry");
        updateIconSidebar(e);
      },
      icon: <FeatherIcon icon="inbox" className="icon-dual" />,
      link: ROUTES.INQUIRY_MANAGEMENT.INQUIRY,
      stateVariables: menuActive === "INQUIRY",
      isShow: isHavePermissionRole(
        ROLES_FOR_APP.CUSTOMER_INQUIRY_MANAGEMENT,
        userPermissions
      ),
      classItem: "hidden-after",
      subItems: [
        {
          id: "inquiry_home_page",
          link: ROUTES.INQUIRY_MANAGEMENT.INQUIRY_HOMEPAGE,
          isShow: false,
          stateVariables: true,
        },
      ],
    },
    {
      id: "multilevel14",
      label: "Presscon Ai",
      icon: (
        <img
          src={pressconLogo}
          alt="presscon logo"
          height="15"
          width={"15"}
          style={{ marginRight: "0.665rem" }}
        />
      ),
      // icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "CMS_HUB_MANAGEMENT");
        setIsCurrentState((prev) => "CMS Hub Management");
        updateIconSidebar(e);
      },
      stateVariables:
        menuActive === "CMS_HUB_MANAGEMENT" ||
        menuActive === "CMS_HUB_MANAGEMENT",
      isShow: isHavePermissionRole(
        ROLES_FOR_APP.CMS_MANAGEMENT,
        userPermissions
      ),
      subItems: [
        {
          id: "statistics",
          label: t("cms_statistics.statistics"),
          link: ROUTES.CMS_HUB_STATISTICS,
          isShow: true,
          stateVariables: true,
        },
        {
          id: "CMS_HUB_MEDIA_MANAGEMENT",
          label: t("media.Management"),
          link: ROUTES.CMS_HUB_MEDIA_MANAGEMENT,
          isShow: true,
          stateVariables: true,
        },
        {
          id: "CMS_HUB_MEMBERSHIP",
          label: t("membership.membership_management"),
          link: ROUTES.CMS_HUB_MEMBERSHIP,
          isShow: true,
        },
      ],
    },
    {
      id: "account-management",
      label: t("account.menu_title"),
      click: function (e: any) {
        setMenuActive((prev) => "ACCOUNT");
        setIsCurrentState((prev) => "account");
        updateIconSidebar(e);
      },
      icon: (
        <FeatherIcon
          icon="user"
          className="icon-dual"
        />
      ),
      link: ROUTES.ACCOUNT,
      stateVariables: menuActive === "account",
      isShow: isSuperAdmin(),
      classItem: "hidden-after",
      subItems: [
          {
            id: "account_create",
            link: ROUTES.ACCOUNT_CREATE,
            isShow: false,
            stateVariables: true,
          },
          {
            id: "account_update",
            link: ROUTES.ACCOUNT_UPDATE,
            isShow: false,
            stateVariables: true,
          }
        ],
    },
    {
      id: "import-management",
      label: t("cms_media.import"),
      click: function (e: any) {
        setMenuActive((prev) => "IMPORT");
        setIsCurrentState((prev) => "import");
        updateIconSidebar(e);
      },
      icon: (
        <FeatherIcon
          icon="database"
          className="icon-dual"
        />
      ),
      link: ROUTES.IMPORT,
      stateVariables: menuActive === "import",
      isShow: isSuperAdmin(),
      classItem: "hidden-after",
      subItems: [ ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
