import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";
//
import "react-toastify/dist/ReactToastify.css";
import useQueryParams from "components/Hooks/useQueryParams";
import TableContainer from "../../../components/Common/TableContainer";
import {
  catchAsync,
  formatDate,
  formatNumber,
  formatWithKoreanAmPm,
} from "helpers/general";
import { IPagination } from "api/types/general";
import DatePickerCustom from "components/Common/DatePickerCustom";
import cmsHubMembershipApi from "api/membershipApi";
import CardSummary from "pages/Media/Components/CardSummary";
import { IMembershipSumary, IPayment } from "api/types/_membership";
import { useModal } from "context/confirm.context";

const PaymentTab = () => {
  const { showModal } = useModal();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 30,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  };
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const defaultRangeDate = [
    moment(queryParams?.start_date || "", "Y-MM-DD").toDate(),
    moment(queryParams?.end_date || "", "Y-MM-DD").toDate(),
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;

  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<IPayment>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [summaries, setSummaries] = useState<IMembershipSumary>();

  // Column
  const columns = useMemo(() => {
    return [
      {
        Header: t("membership.number"),
        accessor: "id",
        sortable: false,
        Cell: ({ row }: any) => (
          <div>
            {pagination.total -
              pagination.page_size * (pagination.page - 1) -
              (row?.index || 0)}
          </div>
        ),
      },
      {
        Header: t("membership.media_name"),
        accessor: "company_name",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">{row.original?.company_name || "-"}</div>
        ),
      },
      {
        Header: t("membership.product"),
        accessor: "product_name",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">{row.original?.product_name || "-"}</div>
        ),
      },
      {
        Header: t("membership.amount"),
        accessor: "amount",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {formatNumber(row.original?.amount)}
          </div>
        ),
      },
      {
        Header: t("membership.payment_date"),
        accessor: "payment_date",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {formatWithKoreanAmPm(row.original?.payment_date)}
          </div>
        ),
      },
      {
        Header: t("membership.next_payment_date"),
        accessor: "next_payment_date",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {formatWithKoreanAmPm(row.original?.next_payment_date)}
          </div>
        ),
      },
      {
        Header: t("membership.situation"),
        accessor: "is_auto_renewal",
        filterable: false,
        sortable: false,
        Cell: ({ row }: any) => (
          <div className="d-flex align-items-center">
            <div style={{ minWidth: "80px" }}>
              {row.original?.is_auto_renewal
                ? t("membership.automatic_renewal")
                : <div style={{marginLeft: "20px"}}>-</div>}
            </div>
            {row.original?.can_cancel_payment ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=>{
                    showModal(
                        {
                          message: (
                            <div className="d-flex" style={{flexDirection:'column',gap: '30px'}}>
                                <div>
                                    <div className="d-flex gap-3">
                                        <div style={{minWidth: "60px",textAlign: "left",fontWeight: "700" }}>{t('membership.date_of_purchase')}</div>
                                        <div>{formatDate(row.original?.payment_date,"YYYY.MM.DD HH:mm:ss")}</div>
                                    </div>
                                    <div className="d-flex gap-3">
                                        <div style={{minWidth: "60px",textAlign: "left",fontWeight: "700" }}>{t('membership.point')}</div>
                                        <div>{row.original?.point}p</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex gap-3">
                                        <div style={{minWidth: "60px",textAlign: "left",fontWeight: "700" }}>{t('membership.refund_amount')}</div>
                                        <div>{formatNumber(row.original?.amount)}원</div>
                                    </div>
                                </div>
                                <div>
                                   {t('membership.refund_description')}
                                </div>
                            </div>
                          ),
                          title: t("membership.payment_cancellation_2"),
                          submitButtonText: t("membership.payment_cancellation_2"),
                          cancelButtonText: t("account.btn.cancel"),
                        },
                        async () => {
                            return cmshubCancelPayment(row.original?.id);
                        }
                      );
                }}
              >
                {t("membership.payment_cancellation")}
              </button>
            ) : null}
          </div>
        ),
      },
    ];
  }, [t, i18n?.language, pagination]);

  const paging = async () => {
    queryParams.start_date = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;
    queryParams.end_date = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : null;
    const [err, result] = await catchAsync(
      cmsHubMembershipApi.cmshubMembership(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  const getSumary = async () => {
    queryParams.start_date = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;
    queryParams.end_date = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : null;
    const [err, result] = await catchAsync(
      cmsHubMembershipApi.cmshubMembershipSumary(queryParams),
      setLoadingSummaries
    );
    if (err) {
      console.log(err);
      return;
    }
    setSummaries(result.data);
  };

  const cmshubCancelPayment = async (id:number) => {
    const [err, _] = await catchAsync(
      cmsHubMembershipApi.cmshubCancelPayment(id),
    );
    if (err) {
      console.log(err);
      return;
    };
    setTimeout(() => {
        paging();
        showModal(
            {
              title: t("membership.guide"),
              message: t("membership.payment_has_been_cancelled"),
              submitButtonText: t("membership.check"),
              isHiddenClose: true,
            },
            () => {}
        );
    }, 0);
    return;
  };

  // Search
  const handleSearchCategory = () => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: 1,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
    });
  };

  const handleChangePage = useCallback((page: any) => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: page + 1,
    });
  }, []);

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  useEffect(() => {
    Promise.allSettled([paging(), getSumary()]);
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location.search]);

  return (
    <React.Fragment>
      <Card id="leadsList">
        <CardHeader className="border-0">
          <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
            <div className="w-100">
              <Row>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mt-2 mt-md-0 mb-2 mb-md-0"
                >
                  <div
                    className="d-flex align-items-center date-picker-wrapper-custom"
                    style={{ gap: "40px" }}
                  >
                    <div className="fw-bold text-nowrap">
                      {t("cms_media.period")}
                    </div>
                    <div style={{ width: "300px" }}>
                      <DatePickerCustom
                        placeholder={`${t("A From Range")} - ${t(
                          "A To Range"
                        )}`}
                        startDate={startDate || null}
                        endDate={endDate || null}
                        disabled={false}
                        isClearable={true}
                        onChangePicker={handleChangePicker}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="g-4 align-items-center mt-0">
                <Col sm={12} md={12} lg={6} xl={6} className="mt-2 mt-md-3">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "40px" }}
                  >
                    <div className="fw-bold text-nowrap">
                      {t("cms_media.search")}
                    </div>
                    <Input
                      type="text"
                      className="form-control search"
                      placeholder={`${t(
                        "membership.please_enter_the_media_name"
                      )}.`}
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearchCategory();
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mt-3 mt-md-3 hstack gap-1 justify-content-sm-center justify-content-md-between"
                >
                  <div className="d-flex gap-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={loading}
                      onClick={handleSearchCategory}
                    >
                      <i className="ri-search-line align-bottom me-1"></i>{" "}
                      {t("Button Search")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary fs-14"
                      onClick={resetData}
                    >
                      <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                      {t("Button Reset")}
                    </button>
                  </div>
                </Col>
              </Row>
              <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2">
                <CardSummary
                  title="membership.total_revenue"
                  value={summaries?.total_revenue || 0}
                  isLoading={loadingSummaries}
                />

                <CardSummary
                  title="membership.membership_revenue"
                  value={summaries?.membership_revenue || 0}
                  isLoading={loadingSummaries}
                />

                <CardSummary
                  title="membership.point_profit"
                  value={summaries?.point_revenue || 0}
                  isLoading={loadingSummaries}
                />
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="pt-3">
          <div>
            <TableContainer
              columns={columns}
              data={rows || []}
              customPageSize={pagination?.page_size}
              customPageIndex={pagination?.page - 1}
              totalRecords={pagination?.total}
              customPageCount={Math.ceil(
                pagination?.total / pagination?.page_size
              )}
              className="custom-header-css"
              divClass="table-responsive table-card"
              tableClass="align-middle"
              theadClass="table-light"
              handleChangePage={handleChangePage}
              isLoading={loading}
            />
          </div>
          <ToastContainer closeButton={false} limit={1} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PaymentTab;
